<template>
	<nav
		:class="{
			'loading': loading,
			'mobile': mobileMode,
			'desktop': !mobileMode,
		}"
		class="language-switcher"
	>
		<router-link
			v-for="(item, index) in items"
			:key="item.language"
			:to="item.route"
			class="language-link"
			@click.native="$emit('change', item.language)"
			@click.native.prevent.stop="changeLanguage(item.language)"
		>{{ item.caption }}</router-link>
	</nav>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Trans from '@/plugins/Translation';

export default {
	name: 'LanguageSwitcher',

	data() {
		return {};
	},

	computed: {
		...mapState(['route']),
		...mapGetters('browser', ['mobileMode']),
		...mapGetters('page', ['loadingStatus']),

		loading() {
			return this.loadingStatus.language;
		},

		items() {
			return Trans.supportedLanguages.map(lang => ({
				language: lang,
				caption: this.$t(`languages.${lang}`),
				route: {
					...this.route,
					params: {
						...this.route.params,
						lang,
					},
				},
			}));
		},
	},
	methods: {
		changeLanguage(language) {
			return Trans.changeLanguage(language)
				.then(() => {
					let willRoute = this.$router.push({ params: { lang: language } });
					if (willRoute instanceof Promise) willRoute.catch(error => {})
				})
				.catch(error => {
					console.debug(error);
				})
		},
	},
};
</script>

<style lang="sass" scoped>
.language-switcher
	&.loading
		opacity: .5

.mobile
.desktop

.language-link
	@extend %button
	text-decoration: none
	border-color: transparent

	&.router-link-active
		border-color: currentColor
</style>
