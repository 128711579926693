/*
 * Throttling.
 * See `https://codeburst.io/throttling-and-debouncing-in-javascript-b01cad5c8edf`
 */
export function throttle(func, limit) {
	let lastFunc;
	let lastRan;

	return function() {
		const context = this;
		const args = arguments;

		if (!lastRan) {
			func.apply(context, args);
			lastRan = Date.now();
		} else {
			clearTimeout(lastFunc);
			lastFunc = setTimeout(function() {
				if (Date.now() - lastRan >= limit) {
					func.apply(context, args);
					lastRan = Date.now();
				}
			}, limit - (Date.now() - lastRan));
		}
	};
}

/*
 * Debouncing.
 * See `https://codeburst.io/throttling-and-debouncing-in-javascript-b01cad5c8edf`
 */
export function debounce(func, delay) {
	let inDebounce;

	return function() {
		const context = this;
		const args = arguments;
		clearTimeout(inDebounce);
		inDebounce = setTimeout(() => func.apply(context, args), delay);
	};
}

/*
 * Find <char> position, the nearest to the <target> position.
 * Receives <string>, <char>, target <target> position
 */
export function nearestChar(string, char, target) {
	let position = string.indexOf(char);
	if (position === -1) return -1;
	let length = string.length;
	let distance = Math.abs(target - position);
	if (distance === 0) return position;
	while (position < length && distance > 0) {
		let next = position + 1 + string.slice(position + 1).indexOf(char);
		let nextDistance = Math.abs(target - next);
		if (next === position || distance < nextDistance) break;
		distance = nextDistance;
		position = next;
	}
	return position;
}

/*
 * The class is representation of grid-layout.
 * Used @ grid-layout component.
 * In fact its an instance of 2-dimensional array,
 * That has few additional getters:
 * `shortest` - returns the shortest column index,
 * `availableLineLength` - returns line length, that can be takken
 */
export class VGrid extends Array {
	constructor(columns) {
		super();
		for (let i = 0; i < columns; i++) this.push([]);
	}

	getRow(y) {
		if (y <= this.heighestRow && y >= 0)
			return this.map(column => column[y]);
		return null;
	}

	get shortest() {
		return this.reduce((shortest, column, index) => {
			return column.length < this[shortest].length ? index : shortest;
		}, 0);
	}

	get availableLineLength() {
		let startCol = this.shortest;
		let row = this[startCol].length;
		let length = 1;

		for (let i = startCol + 1, n = this.length; i < n; i++) {
			if (this[i].length <= row) {
				length++;
			} else {
				break;
			}
		}
		return length;
	}

	get heighestRow() {
		return this.reduce((longest, column) => column.length > longest ? column.length : longest, 0);
	}

	get transponed() {
		return new Array(this.heighestRow).fill('').map((emptiness, index) => this.getRow(index));
	}
}

/*
 * setterGenerator
 * Recieves array of field-names,
 * Returns object of setters, named `field` -> `setField`
 */
export function setterGenerator (fields) {
	return fields.reduce((setters, field) => {
		setters[`set${field[0].toUpperCase() + field.slice(1)}`] = function(state, value) {
			if ({}.hasOwnProperty.call(state, field)) state[field] = value;
		};
		return setters;
	}, {});
}

/*
 * Stupidly removes tags from string
 */
export function removeHTMLTags(string) {
	return string
		.replace(/<br[^>]*>|<\/p>\s*<p(\s[^>]*)?>/ig, ' ')
		.replace(/<\/?[^>]+>/ig, '');
}

/**
 * Removes <p|h[1-6]>, and replaces </p><p> with line-break.
 * @param {String} string
 */
export function cleanOfBlockTags(string, br) {
	br = br || typeof br === 'undefined' ? true : false;

	return string
		.replace(/<\/(p|h[1-6])>\s*<(p|h[1-6])(\s+[^>])?>|<br\s*\/?>/igm, br ? '<br />' : ' ')
		.replace(/^\s*<(p|h[1-6])(\s+[^>])?>|<\/(p|h[1-6])>\s*$/igm, '')
	;
}


/**
 * Resolves static url basing on api url
 * use if api base url different with the site
 */
export function resolveRelativeStaticURL(url) {
	try {
		let baseUrl = process.env.VUE_APP_API_URL.match(/^\w+:\/\/[^/]+/)[0];
		return baseUrl + url;
	} catch (e) {
		if (process.env.NODE_ENV === 'development') {
			console.error(`URL resolving trouble: url '${ process.env.VUE_APP_API_URL }'`);  // eslint-disable-line no-console
		}
	}

	return url;
}


/**
 * Checks if provided url looks like local
 */
export const checkLinkLocal = (() => {
	const host = window.location.host;
	const reLocal = new RegExp(`^(/([^/]|$)|(https?:)?//${host})`, 'i');
	const reBase = /^[^\/]*\/{2}[^\/]+/i;  // eslint-disable-line no-useless-escape

	return function checkLinkLocal(url) {
		if (reLocal.test(url)) {
			return url.replace(reBase, '');
		}
		return null;
	};
})();


/**
 * formating number with char in start
 */
export function numberWithZero(num, length=2, char='0') {
	var r = "" + num;
	while (r.length < length) {
		r = char + r;
	}
	return r;
}


/**
 * Formating Date to 25.04.2019 05:58:41
 */
export function formatDate(date) {
	try {
		if (!(date instanceof Date)) date = new Date(date);
		let year = numberWithZero(date.getFullYear());
		let month = numberWithZero(date.getMonth() + 1);  // months are zero indexed
		let day = numberWithZero(date.getDate());
		// let hour = numberWithZero(date.getHours());
		// let minute = numberWithZero(date.getMinutes());
		// let second = numberWithZero(date.getSeconds());
		return`${day}/${month}/${year}`;
	} catch (error) {
		console.error(`Unable to format following date. "${error.message}"`, date);
		return null;
	}

}

export function tagsDelimiterHelper(tags, maxLength) {
	let currentLength = 0;
	let arr = [];
	let chunk = [];

	for (let [indx, tag] of tags.entries()) {
		currentLength = currentLength + tag.title.length + 3;
		let nextLength = tags[indx + 1] ? tags[indx + 1].title.length : 0;
		chunk.push(tag);
		if (currentLength + nextLength >= maxLength) {
			arr.push(chunk);
			currentLength = 0;
			chunk = [];
		}
	}
	return arr;
}
