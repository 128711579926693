// import { tags, tagGroups, defaultFilterTagGroup } from '@/api';
import { getTags } from '@/api';
import { DEFAULT_GROUP_SLUG } from '@/constants/tags.js';

import { setterGenerator } from '@/utilites.js';

export default {
	namespaced: true,

	state: {
		tags: [],
		groups: [],
		defaultGroup: DEFAULT_GROUP_SLUG,
	},
	mutations: {
		...setterGenerator(['tags', 'groups', 'defaultGroup']),
	},
	getters: {
		allTagsByGroup(state) {
			return state.tags.reduce((all, tag) => {
				if (!{}.hasOwnProperty.call(all, tag.group)) all[tag.group] = [];
				all[tag.group].push(tag);
				return all;
			}, {});
		},
		allGroupsBySlug(state) {
			return state.tags.reduce((all, group) => {
				all[group.slug] = group;
				return all;
			}, {});
		},
		getGroup(state, getters) {
			return slug => state.groups.find(g => g.slug === slug) || null;
		},
		getTagsByGroup(state, getters) {
			return slug => getters.allTagsByGroup[slug] || null;
		},
	},
	actions: {
		getTags({ state, commit }, force) {
			if (force || state.groups.length === 0) {
				// setLoadingStatus
				commit('page/addLoadingStatus', 'tags', { root: true });

				return getTags().then(data => {
					// removeLoadingStatus
					commit('page/removeLoadingStatus', 'tags', { root: true });

					commit('setGroups', data.items
						.map(group => ({
							title: group.title,
							slug: group.slug,
						}))
						.sort((groupA, groupB) => {
							if (groupA.title > groupB.title) return 1;
							if (groupA.title < groupB.title) return -1;
							return 0;
						})
					);


					commit('setTags', data.items.reduce((tags, group, index, groups) => {
						return tags.concat(group.tags
							.map(tag => ({
								...tag,
								group: group.slug,
							}))
							.sort((tagA, tagB) => {
								if (tagA.title > tagB.title) return 1;
								if (tagA.title < tagB.title) return -1;
								return 0;
							})
						);
					}, []));
				})
			} else {
				return Promise.resolve();
			}
		},
	},
}
