import VueI18n from 'vue-i18n';
import Vue from 'vue';
import { DEFAULT_LANGUAGE, FALLBACK_LANGUAGE } from '@/constants/trans';
let default_lang_messages = require('@/locales/'+DEFAULT_LANGUAGE+'.json');

Vue.use(VueI18n);

const i18n = new VueI18n({
	locale: DEFAULT_LANGUAGE,
	fallbackLocale: FALLBACK_LANGUAGE,
	messages: {
		[DEFAULT_LANGUAGE]: default_lang_messages
	}
});

export default i18n;
