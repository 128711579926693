import throttle from 'lodash/throttle';


const DIRECTIVE_NAME = 'isOnScreen';
const ELEM_VISIBILITY_ENAUGH_PART = .75;
const THROTTLING_DELAY = 67;

function segmentIntersection(a1, b1, a2, b2) {
	const intersectionLength = Math.min(b1, b2) - Math.max(a1, a2);
	return intersectionLength > 0 ? intersectionLength : null;
}

/*
 * Triggering callback from binding.value
 * if the element is visible
 * according ELEM_VISIBILITY_ENAUGH_PART
 */
export const directive = {
	bind(el, binding, vnode) {
		const element = el;

		if ({}.hasOwnProperty.call(binding, 'value') && typeof binding.value.handler === 'function') {

			const isOnScreenHandler = event => {
				const dir = binding.arg;

				// top, right, bottom, left, width, height
				const { top, right, bottom, left, width, height} = element.getBoundingClientRect();
				const vWidth = window.innerWidth;
				const vHeight = window.innerHeight;

				// calculate element visibility by x
				const wide = width >= vWidth * ELEM_VISIBILITY_ENAUGH_PART;
				const xIntersection = segmentIntersection(0, vWidth, left, right);
				const visibleByX = wide && xIntersection >= vWidth * ELEM_VISIBILITY_ENAUGH_PART
					|| !wide && xIntersection  >= (width * .99);

				// calculate element visibility by y
				const high = height >= vHeight * ELEM_VISIBILITY_ENAUGH_PART;
				const yIntersection = segmentIntersection(0, vHeight, top, bottom);
				const visibleByY = high && yIntersection >= vHeight * ELEM_VISIBILITY_ENAUGH_PART
					|| !high && yIntersection  >= (height * .99);

				if (dir === 'x' && visibleByX || dir === 'y' && visibleByY || visibleByX && visibleByY) {
					binding.value.handler(binding.value.data);
				}

			}

			element.$isOnScreenHandler = throttle(isOnScreenHandler, THROTTLING_DELAY);
		}
	},

	inserted(el, binding, vnode) {
		if (typeof el.$isOnScreenHandler === 'function') {
			window.addEventListener('scroll', el.$isOnScreenHandler);
		}
	},

	update(el, binding, vnode) {
		window.removeEventListener('scroll', el.$isOnScreenHandler);
		if (typeof el.$isOnScreenHandler === 'function') {
			window.addEventListener('scroll', el.$isOnScreenHandler);
		}
	},

	unbind(el, binding, vnode) {
		window.removeEventListener('scroll', el.$isOnScreenHandler);
	},
};

/**
 * Install function for `Vue.use()` support.
 */
function install(Vue, options) {
	Vue.directive(DIRECTIVE_NAME, directive);
}

export default {
	directive,
	install,
};

