<template>
	<header
		:class="{
			transparent: transparent,
			mobile: mobileMode,
			desktop: !mobileMode
		}"
		class="header-panel"
	>
		<div class="header-wrapper">
			<!-- logo -->
			<router-link :to="homeRoute" class="header__branding" @click.native="hideMenu"
				><svg class="wais-logo" width="198" height="29" >
					<use xlink:href="#glyph--wais-logo" href="#glyph--wais-logo" />
				</svg></router-link>

			<!-- menu drop down and burger-button if mobile -->
			<template v-if="mobileMode">
				<burger-button
					:opened="menuVisibility"
					:custom-evt="'toggle'"
					class="burger-button"
					custom-class-name="burger-button__glyph"
					@toggle="toggleMenuVisability"
				/>

				<transition name="drop-down">
					<div
						v-show="menuVisibility"
						v-prevent-body-scroll="menuVisibility"
						class="mobile-menu"
					>
						<menu-overlay calss="menu-overlay" @hide="hideMenu" />

						<footer-panel class="footer"/>
					</div>
				</transition>
			</template>

			<template v-else>
				<nav class="navigation navigation_desktop">
					<router-link
						v-for="(item, index) in menuItems"
						:key="`${index}-link`"
						:to="item.route"
						:class="{ 'is-active': item.isActive }"
						class="navigation-link"
						v-html="item.caption"
					/>
				</nav>

				<!-- language switcher -->
				<language-switcher class="header__lang-sw" />
			</template>
		</div>
	</header>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import BurgerButton from "./burger-button.vue";
import LanguageSwitcher from "@/components/language-switcher";
import MenuOverlay from "@/components/menu-overlay.vue";
import FooterPanel from "@/components/footer";

import { ALL_PROJECTS_ROUTE_NAME } from "@/constants/router-strings";
import { PAGE_TYPES } from "@/constants/page-types";
import { removeHTMLTags } from "@/utilites.js";

export default {
	name: "HeaderPanel",
	data() {
		return {
			menuVisibility: false
		};
	},

	components: {
		BurgerButton,
		FooterPanel,
		LanguageSwitcher,
		MenuOverlay,
	},

	computed: {
		...mapState(["route"]),
		...mapGetters("browser", ["aspectRatio", "mobileMode"]),
		...mapState("browser", ["pageXOffset", "pageYOffset"]),
		...mapState("pages", { pages: "list" }),

		menuItems() {
			return this.pages
				.filter(page => page.on_header)
				.map(page => {
					let name = page.meta.slug;
					let isActive = name === this.route.name;
					if (page.meta.type === PAGE_TYPES.OUR_WORK) {
						name = ALL_PROJECTS_ROUTE_NAME;
					}
					return {
						caption: removeHTMLTags(page.title),
						isActive,
						route: {
							name,
							params: {
								lang: this.route.params.lang
							}
						}
					};
				});
		},

		transparent() {
			return (
				this.route.meta.pageType === PAGE_TYPES.HOME &&
				!this.menuVisibility &&
				this.pageXOffset === 0 &&
				this.pageYOffset === 0
			);
		},

		homeRoute() {
			const homePage = this.pages.find(page => page.meta.type === PAGE_TYPES.HOME);
			try {
				return {
					name: homePage.meta.slug,
					params: { lang: this.route.params.lang }
				};
			} catch (error) {
				console.error(`Something wrong with home [sub]page: ${error.mesage}`);
				return null;
			}
		}
	},

	methods: {
		removeHTMLTags,
		toggleMenuVisability() {
			this.menuVisibility = !this.menuVisibility;
		},
		hideMenu() {
			this.menuVisibility = false;
		}
	},

	watch: {
		route(route) {
			this.hideMenu();
		}
	},
};
</script>

<style lang="sass" scoped>
// Panel:
.header-panel
	position: fixed
	top: 0
	right: 0
	left: 0
	z-index: 2
	transition: background-color .2s ease
	transform: translateZ(0) // flow hack

	&.mobile
		height: rslv($hp__height__mob)

	&.desktop
		height: rslv($hp__height)

	&::before
		content: ""
		display: block
		position: absolute
		top: 0
		right: 0
		bottom: 0
		left: 0
		background-color: white
		transition: transform .2s ease, opacity .2s ease

	&.transparent
		color: invert($clr__def)

		&::before
			transform: translate3d(0, -100%, 0)
			opacity: .5


// Wrapper:
.header-wrapper
	position: relative
	display: flex
	flex-direction: row
	align-items: center
	height: 100%
	box-sizing: border-box

	.mobile &
		width: 100%
		justify-content: center

	.desktop &
		justify-content: space-between
		padding: 0 rslv($hp__padding-x)


// Logo:
.header__branding
	position: relative
	z-index: 1
	display: inline-block
	padding: 0
	height: rslv($hp__logo-height)
	color: inherit

	.mobile &
		height: rslv($hp__logo-height__mob)

// Logo inner object
.wais-logo
	height: 100%
	width: auto
	margin: 0

// Nav:
.navigation
	display: flex
	flex-wrap: nowrap
	align-items: stretch
	justify-content: center

	.desktop &
		flex-direction: row
		height: rslv($hp__item-height)
		margin: 0 rslv($hp__padding-x)
		flex-shrink: 1

.navigation-link
	@extend %button
	--height: 40px
	text-decoration: none
	border-color: transparent

	&.router-link-active, &.is-active
		border-color: currentColor


.burger-button
	.mobile &
		position: absolute
		z-index: 1
		right: 0

.burger-button__glyph
	fill: currentColor



.mobile-menu
	position: fixed
	z-index: -1
	top: 0
	left: 0
	right: 0
	height: 100vh
	height: calc(100 * var(--vh, 1vh))

	display: flex
	flex-direction: column
	overflow: auto

.menu-overlay
	height: 100vh
	height: calc(100 * var(--vh, 1vh))
	flex-shrink: 0

.footer
	flex-shrink: 0


// transition
.drop-down-enter-active,
.drop-down-leave-active
	transition: transform .2s ease

.drop-down-enter,
.drop-down-leave-to
	transform: translate3d(0, -100%, 0)
</style>
