<template>
	<button
		:class="{
			'burger-button_mobile': mobileMode,
			'burger-button_desktop': !mobileMode,
		}"
		type="button"
		class="burger-button"
		@click="clickHandler"
	>
		<svg class="burger-button__icon" width="75" height="75" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
			<defs>
				<mask :id="maskIds.menu" mask-type="alpha" maskUnits="userSpaceOnUse" x="25" y="29" width="25" height="17">
					<path fill-rule="evenodd" clip-rule="evenodd" d="M50 29H25V31H50V29ZM50 44H25V46H50V44Z" fill="white" />
				</mask>
				<mask :id="maskIds.close" mask-type="alpha" maskUnits="userSpaceOnUse" x="25" y="25" width="25" height="25">
					<path fill-rule="evenodd" clip-rule="evenodd" d="M35.9602 37.3744L25 48.3345L26.4142 49.7487L37.3744 38.7886L48.3345 49.7487L49.7487 48.3345L38.7886 37.3744L49.7487 26.4142L48.3345 25L37.3744 35.9602L26.4142 25L25 26.4142L35.9602 37.3744Z" fill="white" />
				</mask>
			</defs>
			<g :mask="`url(#${opened ? maskIds.close : maskIds.menu})`" :class="customClassName">
				<rect x="0" y="0" width="75" height="75"/>
			</g>
		</svg>
	</button>
</template>

<script>
import { mapGetters } from 'vuex';

const MASK_IDS = {
	close: 'burger__close-icon',
	menu: 'burger__menu-icon',
};

export default {
	name: 'BurgerButton',
	data() {
		return {
			maskIds: MASK_IDS,
		};
	},
	props: {
		opened: {
			type: Boolean,
			required: false,
		},
		customEvt: {
			type: String,
			default: 'toggle',
		},
		customClassName: {
			type: String,
			default: 'burger-button__glyph'
		},
	},
	computed: {
		...mapGetters('browser', ['mobileMode'])
	},
	methods: {
		clickHandler(evt) {
			this.$emit(this.customEvt);
		},
	},
}
</script>

<style lang="sass">
.burger-button
	@extend %clean-button
	display: inline-block
	height: 100%

.burger-button_mobile
	width: rslv($hp__height__mob)

.burger-button_desktop
	width: rslv($hp__height)

.burger-button__icon
	height: 100%
	width: 100%

.burger-button__glyph
	fill: currentColor
</style>
