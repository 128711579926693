export const DEFAULT_LANGUAGE = 'ru';
export const FALLBACK_LANGUAGE = DEFAULT_LANGUAGE;

function _get_available_langs(r) {
	/**
	 * Parse folder and generate list of languages
	 */
	let langs = [];
	for (let k of r.keys()) {
		langs.push(k.split("/").slice(-1)[0].slice(0, -5));  // .json - 5 chars
	}
	return langs;
}

let langs_paths = require.context('@/locales', false, /\.json$/);
export const SUPPORTED_LANGUAGES = _get_available_langs(langs_paths);
