import Vue from 'vue';
import Router from 'vue-router';
import LangRouter from '@/views/page-lang-router.vue';
import Trans from '@/plugins/Translation';

import store from '@/store';

import { PROJECT_ROUTE_NAME, ALL_PROJECTS_ROUTE_NAME, E404_ROUTE_NAME } from '@/constants/router-strings.js';
import { PAGE_TYPES, COMPONENTS_BY_TYPES } from '@/constants/page-types';

const ALL_WORKS_SLUG = 'all-work';
const WORK_DETAIL_SLUG = 'work';


function _get_path(page) {
	/**
	 * Check if page is main and return actual slug
	 * main page will have a path length of 8 chars
	 */

	return page.meta.path.length === 8 ? '' : page.meta.slug;
}

function _load(component) {
	return () => import(/* webpackChunkName: "[request]" */ `@/views/${component}`);
}


Vue.use(Router);

export default (pages) => {
	let routes = [];
	for (let page of pages) {
		let path = _get_path(page);

		if (page.meta.type === PAGE_TYPES.OUR_WORK) {
			routes.push({
				path: path,
				name: page.meta.slug,
				meta: {
					pageType: page.meta.type,
				},
				component: _load('page-index'),
			});

			routes.push({
				path: `${path}/list`,
				name: ALL_PROJECTS_ROUTE_NAME,
				component: _load('page-all-work'),
			});

			routes.push({
				path: `${path}/:slug`,
				name: PROJECT_ROUTE_NAME,
				component: _load('page-work'),
			});

		} else if (page.on_landing || path === '') {
			// landing
			routes.push({
				path: path,
				name: page.meta.slug,
				meta: {
					pageType: page.meta.type,
				},
				component: _load('page-index'),
			});

		} else {
			// standalone page
			if (COMPONENTS_BY_TYPES.hasOwnProperty(page.meta.type)) {
				routes.push({
					path: path,
					name: page.meta.slug,
					meta: {
						pageType: page.meta.type,
						standalone: true,
					},
					component: _load(COMPONENTS_BY_TYPES[page.meta.type]),
				});
			}
		}
	}

	routes.push({
		path: '*',
		name: E404_ROUTE_NAME,
		component: _load('page-not-found'),
	});

	/**
	 * Create router instance
	 */
	const router = new Router({
		mode: 'history',


		routes: [
			{
				path: '/:lang',
				component: LangRouter,
				beforeEnter: Trans.routeMiddleware,
				props: Trans.routeProps,
				children: routes
			},
			{
				// Redirect user to supported lang version.
				path: '*',
				redirect(to) {
					return Trans.getUserSupportedLang();
				}
			}
		],

		scrollBehavior(to, from, savedPosition) {
			if (to.meta.standalone) return { x: 0, y: 0 };

			if (to.params.routeByScroll || to.params.preventScroll) return null;
			switch (to.name) {
				case pages.find(p => p.meta.slug === to.name) && pages.find(p => p.meta.slug === to.name).meta.slug:
					// return { selector: `#${to.name}`, offset: { x: 0, y: 0 } };
					return null;
					// break;

				case ALL_WORKS_SLUG:
					return { x: 0, y: 0 };
					// break;

				case WORK_DETAIL_SLUG:
					if (from.name === WORK_DETAIL_SLUG) {
						return savedPosition;
					}
					return { x: 0, y: 0 };

				case 'not-found':
					return { x: 0, y: 0 };
					// break;

				default:
					if (from && savedPosition) {
						return savedPosition;
					}
					return { x: 0, y: 0 };
			}
		},
	});


	router.beforeEach((to, from, next) => {
		// store.commit();
		next();
	});

	return router;
};
