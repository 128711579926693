<template>
	<div
		id="app"
		:style="{
			'--init-vh': init_vh,
			'--vh': vh,
		}"
	>
		<header-panel
			v-if="pages"
		/>
		<router-view/>
		<footer-panel v-if="!mobileMode" class="footer" />
		<transition name="preloader-fade">
			<preloader
				v-if="!initialized || loadingStatus.pages || loadingStatus.language"
				v-prevent-body-scroll
			/>
		</transition>
	</div>

</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import HeaderPanel from '@/components/header-panel';
import FooterPanel from '@/components/footer';
import Preloader from '@/components/preloader';

export default {
	name: 'App',


	data() {
		return {
			initialized: false,
			init_vh: `${window.innerHeight / 100}px`,
		};
	},


	components: {
		HeaderPanel,
		FooterPanel,
		Preloader
	},


	computed: {
		// ...mapGetters('page', ['mobileMode']),
		...mapState('pages', { pages: 'list' }),
		...mapState('browser', { platform: 'platform', windowHeight: 'height' }),
		...mapGetters('browser', ['mobileMode']),
		...mapGetters('page', ['loadingStatus']),

		vh() {
			return `${this.windowHeight / 100}px`;
		},
	},


	mounted() {
		 // setTimeout(() => {
		 // 	this.initialized
		 // })
		this.initialized = true;
	}
};
</script>

<style lang="sass" scoped>
#app
	position: relative
	min-height: 100%
	display: flex
	flex-direction: column
	justify-content: flex-start

.footer
	margin-top: auto


.preloader-fade-enter-active
	transition: opacity .2s ease
.preloader-fade-leave-active
	transition: opacity .3s ease .1s
.preloader-fade-enter,
.preloader-fade-leave-to
	opacity: 0

</style>
