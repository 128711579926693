<template>
	<footer
		:class="{
			'mobile': mobileMode,
			'desktop': !(mobileMode),
		}"
		class="footer-panel"
	>
		<div class="copy">
			<span v-html="$t('footer.copy', { year })" />{{ ' ' }}<span class="right" v-html="$t('footer.right')" />
		</div>
		<nav class="navigation" >
			<template
				v-for="(item, index) in menuItems"
			>
				<router-link
					:key="`${index}-link`"
					:to="item.route"
					class="navigation-link"
					v-html="item.caption"
				/>
				<span
					v-if="index < menuItems.length - 1"
					v-show="!mobileMode"
					:key="index"
					class="separator"
				/>
			</template>
		</nav>
		<div class="made-by" v-html="$t('footer.made_by')" />
	</footer>
</template>

<script>
	import { mapState, mapGetters } from 'vuex';
	import { removeHTMLTags } from  '@/utilites.js'

	import { PAGE_TYPES } from '@/constants/page-types';
	import { ALL_PROJECTS_ROUTE_NAME } from '@/constants/router-strings';

	export default {
		name: 'FooterPanel',
		data() {
			return {
				year: (new Date()).getFullYear(),
			};
		},

		computed: {
			...mapState(['route']),
			...mapGetters('browser', ['mobileMode']),
			...mapState('pages', { pages: 'list' }),

			menuItems() {
				return this.pages.filter(page => page.on_footer).map(page => {
					let name = page.meta.slug;
					if (page.meta.type === PAGE_TYPES.OUR_WORK) {
						name = ALL_PROJECTS_ROUTE_NAME;
					}
					return {
						caption: removeHTMLTags(page.title),
						route: {
							name,
							params: {
								lang: this.route.params.lang,
							},
						}
					}
				});
			},
		},


		methods: {
			removeHTMLTags,
		},
	};
</script>


<style lang="sass" scoped>
	// Panel:
	.footer-panel
		transition: background-color .2s ease
		transform: translateZ(0) // flow hack
		background-color: #f5f5f5
		display: flex

	.mobile
		@extend %p-mobile
		flex-direction: column
		justify-content: center
		padding: 40px 50px
		align-items: flex-start

	.desktop
		@extend %small-regular-desktop
		flex-direction: row
		justify-content: space-between
		align-items: center
		padding: 30px 40px


	// Nav:
	.navigation
		display: flex
		flex-wrap: nowrap
		justify-content: center

		.mobile &
			flex-direction: column
			align-items: flex-start
			margin: 40px 0

		.desktop &
			flex-direction: row
			align-items: center
			margin: 0 rslv($pad__x)
			flex-shrink: 1

		> .separator
			padding: 0 1em
			&::before
				content: "/"

	.navigation-link
		color: inherit
		display: inline-block

		.mobile &
			margin: 12.5px 0


		.desktop &

		&.router-link-active
			text-decoration-color: transparent


	.copy

		& > span
			display: inline-block

		.right
			color: #6d7278

		// .mobile &
		// 	.right
		// 		display: block

		.desktop &
			width: 33%
			text-align: left

	.made-by
		&::v-deep > a
			color: inherit

		.mobile &

		.desktop &
			width: 33%
			text-align: right

</style>

