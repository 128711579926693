<template>
	<div
		class="menu-overlay"
	>
		<nav class="navigation" >
			<router-link
				v-for="(item, index) in menuItems"
				:key="`${index}-link`"
				:to="item.route"
				class="navigation-link"
				@click.native="$emit('hide')"
				v-html="item.caption"
			/>
		</nav>

		<language-switcher
			class="language"
			@change="$emit('hide')"
		/>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { PAGE_TYPES } from '@/constants/page-types';
import { ALL_PROJECTS_ROUTE_NAME } from '@/constants/router-strings';
import { removeHTMLTags } from  '@/utilites.js';
import LanguageSwitcher from '@/components/language-switcher';



export default {
	name: 'MenuOverlay',

	components: {
		LanguageSwitcher,
	},

	props: {
		show: {
			type: Boolean,
		},
	},

	computed: {
		...mapState(['route']),
		...mapState('pages', { pages: 'list' }),
		...mapState('page', ['language']),
		...mapGetters('browser', ['mobileMode']),

		menuItems() {
			return this.pages.filter(page => page.on_header).map(page => {
				let name = page.meta.slug;
				if (page.meta.type === PAGE_TYPES.OUR_WORK) {
					name = ALL_PROJECTS_ROUTE_NAME;
				}
				return {
					caption: removeHTMLTags(page.title),
					route: {
						name,
						params: {
							lang: this.route.params.lang,
						},
					}
				}
			});
		},
	},
};
</script>

<style lang="sass" scoped>
.menu-overlay
	padding: 75px 25px 75px
	box-sizing: border-box

	display: flex
	flex-direction: column
	justify-content: center
	align-items: stretch
	z-index: -1
	pointer-events: auto
	overflow: auto
	transition: transform .2s, opacity .2s ease .2s


	&::before
		content: ""
		position: absolute
		z-index: -1
		top: 0
		right: 0
		bottom: 0
		left: 0

		display: block
		background-color: #ffffff


.navigation
	display: flex
	width: 100%
	// min-width: 66.7%
	// max-width: 100%
	flex-direction: column
	margin: auto
	align-items: flex-start
	align-items: center

.navigation-link
	@extend %button
	text-decoration: none
	border-color: transparent
	font-size: 30px
	--height: 60px

	&.router-link-active
		border-color: currentColor

.language
	display: flex
	justify-content: center
	margin-top: 25px


	// lang-switcher item
	&::v-deep > .language-link
		height: unset
		font-size: 24px
		font-family: $font__ibm
		border: none
		padding: 0

		&:not(:last-child)
			margin-right: 25px

		&.router-link-active
			color: #6d7278

</style>
