import axios from 'axios';
import { ACTUAL_PAGE_TYPES } from '@/constants/page-types';
import Vue from 'vue';


const config = {
	// adapter: cache.adapter,
	baseURL: process.env.VUE_APP_API_URL,
};

if (process.env.VUE_APP_API_AUTH_HEADER) {
	config.headers = {
		Authorization: process.env.VUE_APP_API_AUTH_HEADER,
	};
}

const request = axios.create(config);


export function setHeaders(headers) {
	let commonHeaders = {
		...request.defaults.headers.common,
		...headers,
	};
	request.defaults.headers.common = commonHeaders;
}



async function _send_request(resource, data) {
	const responseData = (await request.get(
		resource,
		data ? { params: data } : null
	)).data;

	return responseData;
}



/**
 * Gets the pages.
 *
 * Skip `types` prop to request all actual page types
 *
 * @param      {(Array|string)}     [types=ACTUAL_PAGE_TYPES]  The types
 * @param      {boolean}            [sort=true]                The sort flag
 *                                                             Sort pages by
 *                                                             page.meta.path
 * @param      {(string|string[])}  [fields=null]              The fields
 * @param      {number}             limit                      The limit
 * @return     {Promise}            The pages.
 */
export async function getPages(types=ACTUAL_PAGE_TYPES, sort=true, fields='*', limit) {
	const requestData = {};
	if (typeof types === 'string') {
		types = [types];
	}

	if (fields instanceof Array) {
		fields = fields.join(',');
	}

	if (fields && !(typeof fields === 'string')) {
		fields = '*';
	}

	if (typeof limit !== 'undefined') {
		requestData.limit = limit;
	}

	if (fields) {
		requestData.fields = fields;
	}

	const response = await Promise.all(types.map(type => _send_request('pages/', {...requestData, type})));
	const pages = response.reduce((pages, part) => {
		return pages.concat(part.items);
	}, []);

	if (sort) {
		pages.sort((Pa, Pb) => Pa.meta.path - Pb.meta.path);
	}

	return {
		meta: {
			total_count: pages.length,
		},
		items: pages,
	};
}


export function getPage(id) {
	return _send_request(`pages/${id}/`);
}


export function getTags() {
	return _send_request('tags/');
}
