import { setterGenerator } from '@/utilites.js';

export default {
	namespaced: true,
	state: {
		headerLang: null,
		language: null,
		loading: [],
	},

	mutations: {
		...setterGenerator(['headerLang', 'language', 'loading']),
		addLoadingStatus(state, stuff) {
			state.loading.push(stuff);
		},
		removeLoadingStatus(state, stuff) {
			let i = state.loading.indexOf(stuff);
			if (i === -1) throw new Error(`Cannot find '${stuff}' in current loading status.`);

			state.loading.splice(i, 1);
		},
	},
	getters: {
		loadingStatus(state) {
			return state.loading.reduce((status, stuff) => {
				status[stuff] = true;
				return status;
			}, {});
		},
	},
};
