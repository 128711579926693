<template>
	<section class="preloader">
		<svg class="preloader__logo" ><use xlink:href="#glyph--wais-logo" href="#glyph--wais-logo"/></svg>
	</section>
</template>

<script>
export default {
	name: 'Preloader',
};
</script>

<style lang="sass" scoped>
.preloader
	will-change: transform
	position: fixed
	z-index: 10
	transform: translateZ(0)
	top: 0
	right: 0
	bottom: 0
	left: 0
	display: flex
	flex-direction: column
	align-items: center
	justify-content: center
	padding: 25px
	background-color: #ffffff

.preloader__logo
	display: block
	max-width: calc(100% - 80px)
	width: 375px
	height: auto
</style>
