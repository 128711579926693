import { getPages } from '@/api';

export default {
	namespaced: true,

	state: {
		list: [],
	},
	getters: {
		getPage(state, page) {
			return state.list ? state.list[page] : null;
		},
		getMenu(state) {
			/**
			 * Return list of pages that need show in menu
			 */

			return state.list.filter(page => page.meta.show_in_menus);
		},
	},
	mutations: {
		setAllPages(state, sorted_array) {
			state.list = sorted_array;
		},
	},
	actions: {
		getPages({commit, state}, force) {
			/**
			 * request all pages and parse them
			 */




			if (state.list.length === 0 || force) {
				// setLoadingStatus
				commit('page/addLoadingStatus', 'pages', { root: true });
				return getPages()
					.then(data => {
						// removeLoadingStatus
						commit('page/removeLoadingStatus', 'pages', { root: true });
						if (typeof data.items !== "object") throw new Error('Api response not acceptable.');
						commit('setAllPages', data.items);
						return state.list;
					})
					.catch(err => console.error(err.message))  // eslint-disable-line no-console
				;
			} else {
				return new Promise((resolve, reject) => {
					return state.list
				});
			}
		}
	}
};
