/**
 * Hardcoded pages types, get them from backend
 */

const PAGES = [
	{
		name: 'DEFAULT',
		type: 'home.StandardPage',
		component_name: 'page-standard',
	},
	{
		name: 'HOME',
		type: 'home.HomePage',
		component_name: 'page-home',
	},
	{
		name: 'JOIN_US',
		type: 'home.JoinUs',
		component_name: 'page-join-us',
	},
	{
		name: 'OUR_WORK',
		type: 'project.ProjectIndexPage',
		component_name: 'page-our-work',
	},
	{
		name: 'PROJECT',
		type: 'project.ProjectPage',
		component_name: 'page-project',
	},
	{
		name: 'PRESS',
		type: 'home.Press',
		component_name: 'page-press',
	},
	{
		name: 'TEAM',
		type: 'home.Team',
		component_name: 'page-team',
	},
];


export const PAGE_TYPES = PAGES.reduce((pageTypes, page) => ({
	...pageTypes,
	[page.name]: page.type,
}), {});


// Исключаем проекты, так как информация о проектах у нас запрашивается отдельно
export const ACTUAL_PAGE_TYPES = [
	'DEFAULT',
	'HOME',
	'JOIN_US',
	'OUR_WORK',
	'PRESS',
	'TEAM'
].map(name => PAGE_TYPES[name]);


export const COMPONENTS_BY_TYPES = PAGES.reduce((components, page) => ({
	...components,
	[page.type]: page.component_name,
}), {});
