import { getPages, getPage } from '@/api';
import { PAGE_TYPES } from '@/constants/page-types';

export default {
	namespaced: true,

	state: {
		list: [],
		// HACK: TODO: make paginated API work
		limit: 1000,
	},

	mutations: {
		setAllProjects(state, projectPages) {
			state.list = projectPages.map(pPage => {
				const cellWH = pPage.size.split('x');
				const project = {
					...pPage,
					cell: { w: parseInt(cellWH[0]), h: parseInt(cellWH[1]), },
				};
				return project;
			});
		},
	},

	actions: {
		async getProject(context, id) {
			try	{
				const project = await getPage(id)
				return {
					...project,
					project_time: project.project_time.map(timeData => new Date(timeData.time))
				};
			} catch (error) {
				const response = error.response.data;
				console.error(`Error while requet project #${id}`, response); // eslint-disable-line no-console
				throw error;
			}
		},


		getProjects({ state, commit, rootState }, force) {
			if (force || state.list.length === 0) {
				// set loading status
				commit('page/addLoadingStatus', 'projects', { root: true });
				const fields = [
					'description',
					'image',
					'project_time',
					'tags',
					'base64',
					'size',
					'on_main',
					'language_en',
				];
				return getPages(PAGE_TYPES.PROJECT, false, fields, state.limit)
					.then(data => {
						// remove loading status
						commit('page/removeLoadingStatus', 'projects', { root: true });
						if (typeof data.items !== "object") throw new Error('Api response not acceptable.');

						let projects = [];
						if (data.items) {
							projects = data.items
							.filter(item => {
								return rootState.page.headerLang !== 'en' || item.language_en
							})
							.map(item => {
								return {
									...item,
									//
									project_time: item.project_time.map(timeData => new Date(timeData.time)),
								}
							});
						}

						commit('setAllProjects', projects);
						return state.list;
					})
					.catch(err => console.error(err.message)) // eslint-disable-line no-console
				;
			} else {
				return Promise.resolve();
			}
		}
	},
};
