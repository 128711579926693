// import MobileDetect from 'mobile-detect';
// import UA from 'useragent';

import { setterGenerator } from '@/utilites.js';

function getBrowserFamily(userAgent) {
	userAgent = userAgent || window.navigator.userAgent;
	const its = UA.is(userAgent);
	return Object.keys(its).find(browserFamily => its[browserFamily] === true);
}

export default {
	namespaced: true,

	state: {
		width: window.innerWidth,
		height: window.innerHeight,
		platform: 'desktop',
		pageXOffset: window.pageXOffset,
		pageYOffset: window.pageYOffset,
		// family: getBrowserFamily(),
	},

	mutations: {
		...setterGenerator([
			'width',
			'height',
			// 'platform',
			'pageXOffset',
			'pageYOffset',
			'family',
		]),
	},

	getters: {
		aspectRatio(state) {
			return state.width / state.height;
		},

		mobileMode(state, getters) {
			// let byPlatform = false;

			// if (state.platform === 'phone') {
			// 	byPlatform = true;
			// }

			// return byPlatform ||
			return state.width < 750;
		},
	},

	actions: {
		// mobileDetect({ commit }) {
		// 	const md = new MobileDetect(window.navigator.userAgent);
		// 	if (md.mobile()) {
		// 		commit('setPlatform', md.phone() ? 'phone': 'tablet');
		// 	} else {
		// 		commit('setPlatform', 'desktop');
		// 	}
		// },
	},
};
