<template>
	<!-- <transition name="language-switching-fade"> -->
		<router-view />

	<!-- </transition> -->

</template>

<script>
import { mapState } from 'vuex';
import Trans from '@/plugins/Translation';

export default {
	computed: {
		...mapState(['route']),
		...mapState('page', ['language', 'loading']),

		langIsUpdating() {
			return this.loading.includes('language');
		}
	},

	// watch: {
	// 	route: {
	// 		deep: true,
	// 		handler(newRoute, oldRoute) {
	// 			if (newRoute.params.lang === this.language) return null;
	// 			Trans.changeLanguage(newRoute.params.lang);
	// 		},
	// 	},
	// },
};
</script>

<style >

</style>
