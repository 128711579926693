import browser from './browser';
import tags from './tags';
import page from './page';
import pages from './pages';
import projects from './projects';

export default {
	browser,
	tags,
	page,
	pages,
	projects
};
