import throttle from 'lodash/throttle';

const THROTTLE_DELAY = 66;

/**
 * Refactor please(
 * TODO:
 * 1. Create store module on install
 *    https://forum.vuejs.org/t/register-vuex-module-in-vue-plugin/47741
 *
 */

// Old measures
let windowWidth = null;
let windowHeight = null;
let userAgent = null;
let pageXOffset = null;
let pageYOffset = null;

/**
 * Generates a throttled scroll handler
 *
 * @param {object} store - Vuex store instance
 * @param {number} throttleDelay - Throttle delay in milliseconds
 *
 * @returns {function} - Throttled scroll handler
 */
function getScrollHandler(store, throttleDelay) {
	throttleDelay = isNaN(parseInt(throttleDelay)) ? THROTTLE_DELAY : parseInt(throttleDelay);

	function scrollHandler(event) {
		if (pageXOffset !== window.pageXOffset) {
			pageXOffset = window.pageXOffset;
			store.commit('browser/setPageXOffset', pageXOffset)
		}

		if (pageYOffset !== window.pageYOffset) {
			pageYOffset = window.pageYOffset;
			store.commit('browser/setPageYOffset', pageYOffset)
		}
	}
	return throttle(scrollHandler, throttleDelay);
}


/**
 * Generates a throttled resize handler
 *
 * @param {object} store - Vuex store instance
 * @param {number} throttleDelay - Throttle delay in milliseconds
 *
 * @returns {function} - Throttled resize handler
 */
function getResizeHandler(store, throttleDelay) {
	throttleDelay = isNaN(parseInt(throttleDelay)) ? THROTTLE_DELAY : parseInt(throttleDelay);

	function resizeHandler(event) {
		// Trigger mutations only if measures changed
		if (windowWidth !== window.innerWidth) {
			windowWidth = window.innerWidth;
			store.commit('browser/setWidth', windowWidth)
		}

		if (windowHeight !== window.innerHeight) {
			windowHeight = window.innerHeight;
			store.commit('browser/setHeight', windowHeight)
		}

		// if (userAgent !== window.navigator.userAgent) {
		// 	store.dispatch('browser/mobileDetect');
		// }
	}
	return throttle(resizeHandler, throttleDelay);
}


/**
 * Vue plugin installer
 *
 * @param
 */
export function install(Vue, options) {
	if (!options) throw new Error('Window size to store directive error: missing options.`');
	if (!options.store) throw new Error('Window size to store directive error: no `store` provided. Please plug it in with `Vue.use(<directive>, { store });`.');

	const store = options.store;
	const throttleDelay = options.throttleDelay;

	windowWidth = window.innerWidth;
	windowHeight = window.innerHeight;
	userAgent = null;
	pageXOffset = window.pageXOffset;
	pageYOffset = window.pageYOffset;

	const scrollHandler = getScrollHandler(store, throttleDelay);
	const resizeHandler = getResizeHandler(store, throttleDelay);
	window.addEventListener('resize', resizeHandler, { passive: true, });
	window.addEventListener('scroll', scrollHandler, { passive: true, });
}

export default {
	install,
};
