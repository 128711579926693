/*
 * Import Vue stuff and root component
 */
import Vue from 'vue';
import router_constructor from './router';
import { sync } from 'vuex-router-sync';
import store from './store';
import App from './app.vue';

/*
 * Plyr
 */
import VuePlyr from 'vue-plyr';
// import 'vue-plyr/dist/vue-plyr.css';
//import 'plyr/dist/plyr.css';

/*
 * Local vue-video-player fork
 */
// import VueVideoPlayer from '@/components/vue-video-player';
// import '@/assets/styles/vendor/video-js.css';

/*
 * Translations
 */
import i18n from '@/plugins/i18n';
import Trans from '@/plugins/Translation';

/*
 * Base styles
 */
import '@/assets/styles/base.sass';

/*
 * Few vue-plugins
 */
import isOnScreen from '@/plugins/is-on-screen.js';
import windowSizeToStore from '@/plugins/window-size-to-store.js';
import preventBodyScroll from '@/plugins/prevent-body-scroll.js';
import vClickOutside from 'v-click-outside';


// Disable vue dev-mode warning
Vue.config.productionTip = false;

// install `window size to store` plugin
Vue.use(windowSizeToStore, { store, throttleDelay: 66 });
Vue.use(isOnScreen);
// Vue.use(VueVideoPlayer);
Vue.use(vClickOutside);
Vue.use(preventBodyScroll);

// Video player plyr https://github.com/redxtech/vue-plyr
Vue.use(VuePlyr);

// Plug i18n in
Vue.prototype.$i18nRoute = Trans.i18nRoute.bind(Trans);

// request pages
store.dispatch('pages/getPages').then(data => {
	// Generate router instance
	const router = router_constructor(data);

	// eslint-disable-next-line
	const unsync = sync(store, router);

	new Vue({
		router,
		i18n,
		store,
		render: h => h(App),
	}).$mount('#app');
});
